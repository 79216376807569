import React, { RefObject, useContext, useEffect } from 'react'
import { Header } from '@/components/header'
import { Title } from '@/components/title'
import NLink from 'next/link'
import Link from '@mui/material/Link'
import { useScrollPosition } from '@/services/scroll-position'
import {
  Sidebar,
  SidebarItem,
  SidebarToggle,
  useSidebar,
  SidebarCloseArea,
} from '@/components/sidebar'
import { Cross } from '@/components/cross'
import { AlertCircle } from '@/components/alert-circle'
import { Power } from '@/components/power-icon'
import { makePage } from '@/components/page'
import {
  filterEvents,
  uiEvents,
  useDefaultObservable,
  useStateFromUiEvents,
  useSubscription,
} from '@/utils/event'
import { useVenueContext } from '@/services/venue'
import { OrderModeOverlay } from '@/components/order-mode-overlay'
import { useAppContext } from '../_app'
import { VasButton, VasButtonProps } from '@/components/vasButton'
import { truly } from 'shared/utils/types'
import { keys, unique } from 'shared/utils/array'
import { isDevBrowser, windowStorage } from '@/utils/env'
import { EyeIcon } from '@/components/eye-icon'
import { AdminAuthorizationStatus } from '@/components/admin'
import { merge, of } from 'rxjs'
import { switchMap, take, tap, delay as rxDelay, distinctUntilChanged } from 'rxjs/operators'
import { LanguageModalMenu } from '@/components/language-modal-menu'
import SettingsOutlined from '@mui/icons-material/SettingsOutlined'
import dynamic from 'next/dynamic'
import { PageMeta } from '@/components/page-meta'
import { HeadPanel } from '@/components/head-panel'
import { useRouter } from 'next/router'
import { useDraggableContainer } from '@/components/draggable'
import Image from 'next/image'
import { serviceBrands } from 'shared/service-brands'
import { capitalize } from 'shared/utils/string'
import TableViewIcon from '@mui/icons-material/TableView'
import { LayoutContext } from '@/components/layout'
import { Trans } from 'react-i18next'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'

const MenuContent = dynamic(
  () => {
    return import('@/components/menu-content')
  },
  {
    ssr: false,
    loading: function Loading() {
      return <div className="h-screen w-full"></div>
    },
  },
)

const CheckoutSidePanel = dynamic(
  () => {
    return import('@/components/checkout-side-panel')
  },
  {
    ssr: false,
  },
)

export type VenuePageViewMode = 'visitor' | 'adminProducts' | 'adminProductGroups'

declare global {
  interface AppUIEventMap {
    ClosedVenueShowMenuClick: {
      type: 'ClosedVenueShowMenuClick'
    }
    ViewAsCustomerClick: {
      type: 'ViewAsCustomerClick'
    }
    ViewAsAdminClick: {
      type: 'ViewAsAdminClick'
    }
    AdminProductGroupViewDoneButtonClick: {
      type: 'AdminProductGroupViewDoneButtonClick'
    }
  }
}

export default makePage({}, function VenuePage() {
  const { t } = useAppContext()
  const venueContext = useVenueContext()!
  const router = useRouter()

  const { venue, admin, billing, orderTypeHasPaymentMethods } = venueContext

  const viewMode$ = useStateFromUiEvents({
    id: `venueViewMode/${venue.name}`,
    startingValue: 'visitor' as VenuePageViewMode,
    storage: windowStorage,
    process: (source, scan) => {
      return admin.authorizationStatus$.pipe(
        switchMap((status) => {
          switch (status.state.type) {
            case 'ok':
              const url = new URL(location.href)
              const adminViewMode = ((): VenuePageViewMode => {
                const fromUrl = url.searchParams.get('viewMode')
                if (fromUrl) {
                  return fromUrl as VenuePageViewMode
                }

                return status.state.viewMode === 'customer' ? 'visitor' : 'adminProducts'
              })()

              return merge(
                of(adminViewMode),
                source.pipe(
                  scan((mode, event) => {
                    switch (event.type) {
                      case 'AdminProductGroupViewDoneButtonClick':
                        return 'adminProducts'
                      case 'ProductGroupNavigatorEditButtonClick':
                        if (mode === 'adminProductGroups') {
                          return 'adminProducts'
                        }
                        setTimeout(() => {
                          const pgSection = document.getElementById('product-groups-section')!
                          window.scrollTo(0, pgSection.offsetTop - 100)
                        })
                        return 'adminProductGroups'
                      default:
                        return mode
                    }
                  }),
                ),
              )
            default:
              return of('visitor' as VenuePageViewMode)
          }
        }),
        distinctUntilChanged(),
        tap((viewMode) => {
          const url = new URL(location.href)
          if (viewMode === 'adminProductGroups') {
            url.searchParams.set('viewMode', viewMode)
          } else {
            url.searchParams.delete('viewMode')
          }
          // never put history.replaceState here!
          // https://github.com/vercel/next.js/issues/7091#issuecomment-704290245
          router.replace(url.toString(), url.toString(), { shallow: true })
        }),
      )
    },
  })
  const viewMode = useDefaultObservable(viewMode$)

  const sidebar = useSidebar()
  const sidebarIsOpen = useDefaultObservable(sidebar.isOpen$)

  const scrollPosition = useScrollPosition()
  useSubscription(
    uiEvents.pipe(filterEvents(['ProductBlockRendered']), take(1), rxDelay(1)),
    () => {
      scrollPosition.tryRestore()
    },
  )
  useEffect(() => {
    scrollPosition.startTracking()
  }, [])

  const { sidebarItems, shouldShowSidebar } = useSidebarConfig(
    orderTypeHasPaymentMethods,
    viewMode,
    admin.authorizationStatus,
  )

  const paymentIsOverdue = (() => {
    switch (billing.subscription.status.type) {
      case 'setup':
        return false
      case 'trial':
      case 'active':
        return billing.subscription.status.paymentStatus.type === 'overdue'
    }
  })()

  const supportedLanguages = unique([venue.language, ...keys(venue.features.languageConfig ?? {})])

  const serviceBrandConfig = serviceBrands[venue.serviceBrand]

  const layoutContext = useContext(LayoutContext)

  return (
    <div className="flex md:mb-[-60px]">
      <PageMeta
        subTitle={
          orderTypeHasPaymentMethods
            ? t('venueName:titleOnlineOrder')
            : t('venueName:titleDigitalMenu')
        }
      />
      <OrderModeOverlay />
      {supportedLanguages.length > 1 && <LanguageModalMenu languages={supportedLanguages} />}
      {shouldShowSidebar && <Sidebar items={sidebarItems} />}
      <SidebarCloseArea>
        {(triggerSidebarCloseAreaClick) => {
          return (
            <div
              className={`flex-shrink-0 w-full ${
                !layoutContext.forceMobileLayout
                  ? 'md:grid md:grid-cols-[1fr,_394px] xl:grid-cols-[1fr,_450px]'
                  : ''
              } `}
            >
              <div
                id="menu-column"
                className="w-full min-w-0 pb-20 md:border-r xl:border-l border-x-ghost dark:border-x-ghostDark"
                onClick={triggerSidebarCloseAreaClick}
              >
                {viewMode === 'adminProductGroups' && (
                  <HeadPanel
                    onClick={() => {
                      uiEvents.next({
                        type: 'AdminProductGroupViewDoneButtonClick',
                      })
                    }}
                    text={t('common:categories')}
                  />
                )}
                {viewMode !== 'adminProductGroups' && (
                  <div>
                    <Header fullImageUrl={venue.headerPictureUrl}>
                      {shouldShowSidebar ? (
                        <div className="p-5 inline-block">
                          <SidebarToggle>
                            {!sidebarIsOpen ? (
                              <SideBarIcon />
                            ) : (
                              <div className="cursor-pointer">
                                <Cross />
                              </div>
                            )}
                          </SidebarToggle>
                        </div>
                      ) : null}
                    </Header>
                    {venue.features.hideTitle ? (
                      <div className="h-4"></div>
                    ) : (
                      <Title title={venue.title} />
                    )}
                    {!paymentIsOverdue && venue.features.menuDescriptionHtml && (
                      <div
                        className="px-5 mb-6"
                        dangerouslySetInnerHTML={{ __html: venue.features.menuDescriptionHtml }}
                      ></div>
                    )}
                    {!paymentIsOverdue && <VasBlock />}
                  </div>
                )}

                <MenuContent {...{ viewMode, paymentIsOverdue }} />

                {venue.serviceBrand === 'foodeon' &&
                  viewMode === 'adminProducts' &&
                  venue.language === 'ru' && (
                    <div className="flex justify-center pt-5">
                      <div className="max-w-webAppMobileWidth p-4">
                        <div className="border p-3 rounded-lg flex-row bg-[#f8f8f8] dark:bg-[#2F3133] border-ghost dark:border-ghostDark">
                          <div>{t('common:weMadeVideoForYou')}</div>
                          <div className="px-5 text-center mt-3">
                            <Trans
                              i18nKey="common:watchEducationalVideo"
                              t={t}
                              components={{
                                videoLink: (
                                  <Link href="https://youtu.be/Bl-kOPM7Xuo" target="_blank" />
                                ),
                                videoIcon: <OndemandVideoIcon />,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="my-6 text-sm text-center text-opaque dark:text-opaqueDark custom-powered-by">
                  {t('venueName:poweredBy')}{' '}
                  <a className="underline" href={serviceBrandConfig.poweredBy.url}>
                    {capitalize(serviceBrandConfig.title)}
                  </a>
                </div>
              </div>
              <CheckoutSidePanel />
            </div>
          )
        }}
      </SidebarCloseArea>
    </div>
  )
})

function PageIcon() {
  return (
    <svg
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2V8H20"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13H8"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17H8"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9H9H8"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function SideBarIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      className="cursor-pointer stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="20.5" r="20.5" className="fill-back dark:fill-backDark" stroke="none" />
      <path d="M11 20.6667H31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 14H31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 27.3333H31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

function useSidebarConfig(
  orderTypeHasPaymentMethods: boolean,
  viewMode: VenuePageViewMode,
  admin: AdminAuthorizationStatus,
) {
  const { t } = useAppContext()
  const { venue } = useVenueContext()!
  const myOrdersSidebarItem: SidebarItem = {
    title: t('common:myOrders'),
    icon: <PageIcon />,
    action: {
      type: 'link',
      href: `/venue/${venue.name}/orders`,
    },
  }

  const termsSidebarItem: SidebarItem = {
    title: t('common:termsOfUse'),
    icon: <AlertCircle />,
    action: {
      type: 'link',
      href: `/venue/${venue.name}/terms`,
    },
  }

  const stoplistSidebarItem: SidebarItem = {
    title: t('common:suspensionList'),
    icon: <Power />,
    action: {
      type: 'link',
      href: `/admin/venue/${venue.name}/stoplist`,
    },
  }

  const viewAsCustomerSidebarItem: SidebarItem = {
    title: t('sidebar:viewAsCustomer'),
    icon: <EyeIcon />,
    action: {
      type: 'onClick',
      onClick: () => {
        uiEvents.next({
          type: 'ViewAsCustomerClick',
        })
      },
    },
  }
  const viewAsAdminSidebarItem: SidebarItem = {
    title: t('sidebar:viewAsAdmin'),
    icon: <EyeIcon />,
    action: {
      type: 'onClick',
      onClick: () => {
        uiEvents.next({
          type: 'ViewAsAdminClick',
        })
      },
    },
  }

  const settingsSidebarItem: SidebarItem = {
    title: t('sidebar:settings'),
    icon: <SettingsOutlined style={{ opacity: '0.6' }} />,
    action: {
      type: 'link',
      href: `/admin/venue/${venue.name}`,
    },
  }

  const adminerSidebarItem: SidebarItem = {
    title: 'Adminer',
    icon: <TableViewIcon style={{ opacity: '0.6' }} />,
    action: {
      type: 'link',
      href: `http://localhost:9091/?pgsql=${
        isDevBrowser
          ? 'foodeon_local_db'
          : 'ip17dix6wsbud71.c6x2hw29xfic.eu-central-1.rds.amazonaws.com'
      }&username=postgres&db=italy&ns=public&select=venue&columns%5B0%5D%5Bfun%5D=&columns%5B0%5D%5Bcol%5D=&where%5B0%5D%5Bcol%5D=name&where%5B0%5D%5Bop%5D=%3D&where%5B0%5D%5Bval%5D=${
        venue.name
      }&where%5B01%5D%5Bcol%5D=&where%5B01%5D%5Bop%5D=ILIKE+%25%25&where%5B01%5D%5Bval%5D=&order%5B0%5D=id&desc%5B0%5D=1&order%5B1%5D=&limit=50&text_length=100`,
    },
  }

  const collectingPersonalData =
    venue.features.vas?.feedback ||
    venue.features.vas?.bookTable ||
    keys(venue.features.orderingTypes ?? {}).length > 0

  const sidebarItems = [
    orderTypeHasPaymentMethods ? myOrdersSidebarItem : null,
    venue.features.termsAndConditionsHtml || collectingPersonalData ? termsSidebarItem : null,
    viewMode === 'adminProducts' && venue.posType === 'local' ? stoplistSidebarItem : null,
    ...(admin.state.type === 'ok'
      ? [
          admin.state.viewMode === 'admin' ? settingsSidebarItem : null,
          admin.state.viewMode === 'customer' ? viewAsAdminSidebarItem : viewAsCustomerSidebarItem,
          admin.state.role === 'root' ? adminerSidebarItem : null,
        ]
      : []),
  ].filter(truly)

  const shouldShowSidebar =
    sidebarItems.length > 0 || venue.features.sidebarInfoHtml || venue.features.contactDetails

  return { sidebarItems, shouldShowSidebar }
}

function VasBlock() {
  const { t } = useAppContext()
  const { venueName, venue } = useVenueContext()!
  const vas = venue.features.vas
  const draggableContainer = useDraggableContainer()

  // if (!admin.featureScope.vas) {
  //   return null
  // }

  const vasItems = [
    vas?.bookTable
      ? {
          title: t('common:bookTable'),
          icon: <VasImage src="/img/components/vas-button/book-table.png" />,
          type: 'bookTable',
          route: {
            href: `/venue/${venueName}/book-table`,
          },
        }
      : null,
    vas?.callWaiter
      ? {
          title: t('callTheWaiter:callWaiter'),
          icon: <VasImage src="/img/components/vas-button/waiter.png" />,
          type: 'callWaiter',
          route: {
            href: `/venue/${venueName}/call-waiter`,
          },
        }
      : null,
    vas?.feedback
      ? {
          title: vas.feedback.buttonCaption
            ? vas.feedback.buttonCaption
            : t('common:sendUsMessage'),
          icon: <VasImage src="/img/components/vas-button/feedback.png" />,
          type: 'feedback',
          url: vas.feedback.url,
          route: {
            href: `/venue/${venueName}/feedback`,
          },
        }
      : null,
  ].filter(truly)

  if (vasItems.length === 0) {
    return null
  }

  if (vasItems.length === 1) {
    const item = vasItems[0]!
    return (
      <div className="px-4 w-full h-16 mb-6">
        <VasItem
          isDraggingRef={draggableContainer.isDraggingRef}
          url={item.url ?? item.route.href}
          caption={item.title}
          icon={item.icon}
          type={item.type}
          shrinkCaption={false}
        />
      </div>
    )
  }

  return (
    <div
      ref={draggableContainer.containerRef}
      className="flex px-4 pb-4 h-auto mb-6 overflow-x-auto no-scrollbar"
    >
      {vasItems.map((item, idx) => {
        return (
          <div className={`h-16 w-40 flex-shrink-0 ${idx > 0 ? 'ml-4' : ''}`} key={idx}>
            <VasItem
              isDraggingRef={draggableContainer.isDraggingRef}
              url={item.url ?? item.route.href}
              caption={item.title}
              icon={item.icon}
              type={item.type}
              shrinkCaption={true}
            />
          </div>
        )
      })}
      <div className="w-4 flex-shrink-0">{/* extra margin to the right */}</div>
    </div>
  )
}

function VasItem(
  props: VasButtonProps & {
    isDraggingRef: RefObject<boolean>
    url: string
  },
) {
  const VasButtonComponent = (
    <VasButton
      caption={props.caption}
      icon={props.icon}
      type={props.type}
      shrinkCaption={props.shrinkCaption}
    />
  )

  return (() => {
    if (props.url.startsWith('http')) {
      return (
        <a
          onClick={(event) => {
            if (props.isDraggingRef.current) {
              event.preventDefault()
            }
          }}
          href={props.url}
        >
          {VasButtonComponent}
        </a>
      )
    }

    return (
      <NLink
        href={props.url}
        prefetch={false}
        onClick={(event) => {
          if (props.isDraggingRef.current) {
            event.preventDefault()
          }
        }}
      >
        {VasButtonComponent}
      </NLink>
    )
  })()
}

function VasImage(props: { src: string }) {
  return (
    <Image
      alt=""
      width={50}
      height={50}
      src={props.src}
      onDragStart={(e) => {
        e.preventDefault()
      }}
    />
  )
}

export {
  getVenueStaticPaths as getStaticPaths,
  getVenueStaticProps as getStaticProps,
} from '@/services/venue'
